import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createOne } from '../API';
import appConfig from '../appConfig';
import { useUserContext } from '../authentication';
import RegistrationContext from '../registrationContext';
import { RouteStateI, UserI } from '../types';
import { useQueryFromPath } from './useQueryFromPath';

export const useNavigation = () => {
  const { pathname, state } = useLocation<RouteStateI>()
  const history = useHistory()
  const { infos } = useContext(RegistrationContext)
  const authentication = useUserContext()
  const [serverError, setServerError] = useState<null | string>(null);
  const [error, setError] = useState(false)
  const locationSourceId = useQueryFromPath("location_source_id");
  const [isLoading, setIsLoading] = useState(false)


  const createAndLogUser = async (user: UserI) => {
    setIsLoading(true)
    if (!infos.user.email || !infos.user.name || !infos.user.password || !infos.user.phone) {
      setServerError("Tous les champs doivent être remplis")
      setIsLoading(false)
      return
    }

    // 1. On crée le USER
    try {
      
      const response = await createOne("users", { attributes: {...user, phone: `+${appConfig.phonePrefix}${user.phone}`} });

      if (response.ok) {
        const { data: { attributes: { email } } } = await response.json()

        // 2. On log le USER
        try {
          const tokenResponse = await createOne("tokens", { attributes: { email: email, password: infos.user.password, expiration: "regular" } });
          if (tokenResponse.ok && infos.user.password) {
            const auth = await authentication.logIn({ email: email, password: infos.user.password })
            history.push(`/infos${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);

          }
        } catch (error) {

          setIsLoading(false)

          if (error.text) {
            // Peut-être aussi régler le bug qui fait une 500
            setServerError(error.text);
          } else if (error.status === 403) {
            setServerError(
              "Une erreur s'est produite, veuillez réessayer"
            );
          }

        }
      }
    } catch (error) {
      setIsLoading(false)

      if (error.text) {
        // Peut-être aussi régler le bug qui fait une 500
        setServerError(error.text);
      } else if (error.status === 403) {
        setServerError(
          "Une erreur s'est produite, veuillez réessayer"
        );
      } else if (error.text === "") {
        setServerError(
          "Une erreur s'est produite, veuillez vérifier que tous les champs sont bien remplis"
        );
      }
    }

  }

  const goNext = () => {
    // if (!infos.location.name || infos.location.name === "") {
    //   // setErrorMessage({ ...errorMessage, title: "L'établissement n'a pas de nom" })
    // }
    // if (isError) {
    //   setErrorMessage({ ...errorMessage, general: "Le formulaire comporte des erreurs, veuillez les corriger avant de continuer" })
    // } else 
    // if (!isError) {
    switch (pathname) {
      case "/moncompte":
          createAndLogUser(infos.user)

        break;
      case "/infos":
        history.push(`/plan${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
        break;
      case "/plan":
        if (state?.isSponso) {
          history.push(`/validation${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);

        } else {
          history.push(`/sponsorisation${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);

        }
        break;
      case "/sponsorisation":
        history.push(`/validation${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
        break;
      case "/validation":
        history.push(`/resume${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
      default:
        break;
    }
    // }
  };


  const goPrev = () => {
    switch (pathname) {
      case "/infos":
        history.push(`/moncompte${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
        break;
      case "/plan":
        if (state?.status === "upgrade") {
          history.push(`/location/${state?.locationId}`)
        } else {
          history.push(`/infos${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
        }
        break;
      case "/sponsorisation":
        if (state?.status === "renewal" || state?.status === "sponso") {
          history.push(`/location/${state?.locationId}`)
        }
        else {
          history.push(`/plan${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
        }
        break;
      case "/validation":
        history.push(`/sponsorisation${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, state);
        break;
      default:
        break;
    }
  }



  return { goPrev, goNext, error, isLoading, serverError }

}
