import { useQuery } from "react-query"
import { fetchList, fetchOne } from "../API"
import { useUserContext } from "../authentication"
import { DynamicPlanI } from "../types"

export const usePlan = (plan: DynamicPlanI["name"]) => {
  const authentication = useUserContext()

    const fetchObject = (plan: DynamicPlanI["name"]) => {
      switch (plan) {
        case "Gold":
          return { id: "3" }
        case "Silver":
          return { id: "2" }
        case "Bronze":
          return { id: "1" }
      }
    };

    const { data, status, } = useQuery<{
      result: DynamicPlanI
    }>(["plans", fetchObject(plan)], () =>
      fetchOne("plans", fetchObject(plan), authentication.accessToken)
    );

    return data?.result && data?.result
}

export const usePlans = () => {
  const authentication = useUserContext()

  const { data, status, } = useQuery<{
    results: DynamicPlanI[];
    pageCount: number;
    total: number;
  }>(
    ["plans"],
    () => fetchList("plans", {}, authentication.accessToken),
  );

  return data?.results && data?.results

}