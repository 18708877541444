import React from 'react'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link, Paper, Typography } from '@material-ui/core'
import { Link as RouterLink, useHistory } from "react-router-dom"
import Loader from './shared/Loader';
import { useLocationInfos } from '../utils/useLocationInfos';
import { PaymentI, ProductI } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      position: "relative",
      minHeight: "150px",
    },
    button: {
      marginTop: theme.spacing(3)
    },
    containerButton: {
      display: 'flex',
      justifyContent: 'center'
    },
    image: {
      width: "75px",
      height: "75px",
      borderRadius: "50%",
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(5)
    },
    textContainer: {
      maxWidth: "70%"
    },
    plan: {
      marginTop: theme.spacing(2)
    },
    location: {
      display: "flex",
      cursor: 'pointer',
    }
  })
);

type Props = {
  id?: string
}

const LocationCard = ({ id }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const { status, location, imageUrl, toBePayed, activeProducts } = useLocationInfos(id)


  const getStatus = (status: ProductI["workflow_state"] | PaymentI["workflow_state"]) => {
    switch (status) {
      case "active":
        return "actif"
      case "awaiting_payment":
        return "en attente de paiement"
      case "awaiting_validation":
        return "en attente de validation"
      case "inactive":
        return "inactif"
      case 'rejected':
        return "rejeté"
      case 'paied':
        return "paied"
      default:
        break;
    }
  }

  return (
    <Paper onClick={() => history.push(`/location/${id}`)} className={classes.container} >
      {status === "loading" && <Loader />}
      {status === "success" && location && (

        <Link
          to={`/location/${id}`}
          className={classes.location}
          underline='none'
          component={RouterLink}
        >

          {imageUrl && <img
            alt={location?.name}
            className={classes.image}
            //style={{ objectFit: "cover" }}
            src={imageUrl}
          />}
          <div className={classes.textContainer}>

            <Typography variant="h5">
              {location.name}
            </Typography  >
            {location.description &&
              <Typography color="textSecondary" >
                {location.description}
              </Typography>}
            <div className={classes.plan}>
              {location?.workflow_state === "awaiting_validation" && <Typography>Établissement en attente de validation</Typography>}
              {location?.workflow_state !== "awaiting_validation" && activeProducts && activeProducts.map(ap => <div key={ap.id}>
                {ap.relation_type !== "add_sponso" && <Typography>Plan {ap.relation_value}. Statut: {getStatus(ap.workflow_state)}</Typography>}
                {ap.relation_type === "add_sponso" && <Typography>Sponsorisation: {ap.duration_in_months} mois. Statut: {getStatus(ap.workflow_state)}</Typography>}

              </div>)}
              {location?.workflow_state !== "awaiting_validation" && toBePayed && toBePayed.map(tbp => <div key={tbp.id}>
                {tbp.relation_type !== "add_sponso" && <Typography>Plan {tbp.relation_value}. Statut: {tbp?.payment?.workflow_state === "awaiting_validation" ? getStatus(tbp?.payment?.workflow_state) : getStatus(tbp.workflow_state)}</Typography>}
                {tbp.relation_type === "add_sponso" && <Typography>Sponsorisation: {tbp.duration_in_months} mois. Statut: {tbp?.payment?.workflow_state === "awaiting_validation" ? getStatus(tbp?.payment?.workflow_state) : getStatus(tbp.workflow_state)}</Typography>}

              </div>)}
            </div>
          </div>
        </Link>
      )
      }
    </Paper >
  )
}

export default LocationCard



