import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import PrevNextButton from './shared/PrevNextButton';
import { RegistrationI } from "../types"
import { useValidator } from '../utils/useValidator';
import { values, isEmpty } from 'lodash';
import { IconButton, useMediaQuery } from '@material-ui/core';
import RegistrationContext from "../registrationContext";
import { useHistory } from "react-router-dom";
import PrevButton from './shared/PrevButton';
import { useTitle } from '../utils/useTitle';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    errorMessages: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 600,
      alignItems: "flex-start",
      margin: "0 auto"
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold
    },
    stepperContainer: {
      position: "relative",

    },
    stepper: {
      paddingTop: theme.spacing(6)

    },


  }),
);

function getSteps() {
  return ['Inscription', 'Établissement', 'Forfait', 'Sponsorisation', 'Validation'];
}

type contextType = {
  infos: RegistrationI;
  setInfos: React.Dispatch<React.SetStateAction<RegistrationI>>,
}

type Props = { activeStep: number, children: any, disabled?:boolean }


const HorizontalStepper = ({ activeStep, children, disabled=false }: Props) => {
  const classes = useStyles();
  const { infos }: contextType = useContext(RegistrationContext)
  const history = useHistory()
  const { title, navTitle } = useTitle()
  const steps = getSteps();
  const { errorMessage, setErrorMessage } = useValidator()

  // check if errorMessage isEmpty
  const [isError, setIsError] = useState(false)
  useEffect(() => {
    setIsError(!values(errorMessage).every(isEmpty))
  }, [errorMessage])



  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.root}>
      <div className={classes.stepperContainer}>

        <PrevButton activeStep={activeStep} />


        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{sm && label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <Typography variant="h5" className={classes.instructions}>{title}</Typography>

      <div>
        {children}
      </div>

      <PrevNextButton activeStep={activeStep} disabled={disabled} />

      <div className={classes.errorMessages}>
        {errorMessage.general && <Typography variant="caption" color="error">{errorMessage.general}
        </Typography>}
        {errorMessage.title && <Typography variant="caption" color="error">L'établissement n'a pas de nom
        </Typography>}
        {errorMessage.email && <Typography variant="caption" color="error">{errorMessage.email}
        </Typography>}
        {errorMessage.web && <Typography variant="caption" color="error">{errorMessage.web}
        </Typography>}
      </div>
    </div>
  );
}

export default HorizontalStepper
