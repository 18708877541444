import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useUserContext } from "../authentication";
import { useHistory, useLocation } from "react-router-dom";
import LocationCard from "./LocationCard";
import MainLayout from "./MainLayout";
import { useContext } from "react";
import RegistrationContext from "../registrationContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery, useQueryClient } from "react-query";
import { useQueryFromPath } from "../utils/useQueryFromPath";
import CustomButton from "./shared/CustomButton";
import { fetchOne } from "../API";
import { LocationInfoI } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: "auto",
    },
    title: {
      paddingBottom: theme.spacing(3),
    },
    containerButton: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

const MyLocations = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state }: any = useLocation();
  const { user, accessToken } = useUserContext();
  const { setInfos } = useContext(RegistrationContext);
  const locationSourceId = useQueryFromPath("location_source_id");
  const queryClient = useQueryClient();

  const { status, data } = useQuery(["users", { id: user?.id }], () => {
    if (user?.id) {
      return fetchOne("users", { id: user?.id, }, accessToken, "owned_locations")
    }
  },
    {
      enabled: Boolean(user?.id),
    });
  const ownedLocations = data?.data && data?.data

  // ON RESET L'OBJET INFOS
  useEffect(() => {
    setInfos({
      location: {},
      location_plan: {},
      user: {},
    });
    (async () => {

      //await queryClient.invalidateQueries();
      // await queryClient.refetchQueries();
    })()

  }, []);

  // const userLocations = user?.owned_locations;

  return (
    <MainLayout>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h4">
          {" "}
          Mes établissements :{" "}
        </Typography>

        {state?.prevPath.pathname === "/resume" &&
          ownedLocations?.length === 0 ? (
          <>
            <Typography>
              Si vous venez de créer votre établissement et qu'il n'est pas encore vivible, patientez quelques instants et rafraichissez votre page.
            </Typography>

            <CircularProgress color="secondary" />
          </>
        ) : (
          <>
            {ownedLocations?.map(({ id, attributes }: { attributes: LocationInfoI, id: string }) => (
              <LocationCard
                key={id}
                id={id}
              />
            ))}
          </>
        )}
        <div className={classes.containerButton}
        >
          <CustomButton
            submitFn={() => history.push(`/infos${locationSourceId ? `?location_source_id=${locationSourceId}` : ""}`, { status: "newLocation" })}
            accent="main"
          >
            Ajouter un établissement
          </CustomButton>

        </div>
      </div>
    </MainLayout>
  );
};

export default MyLocations


