import React from 'react'
import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { LocationInfoI, PaymentI, ProductI } from '../../types'
import Alert from '@material-ui/lab/Alert';
import { useLocationInfos } from '../../utils/useLocationInfos';
import { useLocationId } from '../../utils/useLocationId';
import PaymentAction from './PaymentAction';
import appConfig from '../../appConfig';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toBePayed: {
      marginBottom: theme.spacing(2)
    },
    
    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    alertBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }

  })
)


type Props = {
  toBePayed: ProductI[],
  workflowState: PaymentI["workflow_state"] | LocationInfoI["workflow_state"],
}

const Payment = ({ workflowState, toBePayed }: Props) => {
  const classes = useStyles()


  return (
    <div>
      {toBePayed && <>
        {<Typography className={classes.subtitle} variant='h5'>
          Paiement à régulariser
        </Typography>}

        {toBePayed.map((tbp: ProductI) =>
          <div key={tbp.id} className={classes.toBePayed}>
            <Typography variant="h6" color="textSecondary">
              {tbp.relation_type === "first_plan" && `Souscription au plan ${tbp.relation_value}`}
              {tbp.relation_type === "add_sponso" && "Sponsorisation"}
            </Typography>
            <Typography>
              Durée: {tbp.duration_in_months} mois
              <br />
              Prix: {tbp.displayed_price}
            </Typography>
            {tbp.payment?.workflow_state === "rejected" && <Alert className={classes.alertBtn} severity="warning" >Le paiement a été rejeté, veuillez consulter vos emails</Alert>}
          </div>
        )}

        {/* si l'établissement est en attente de validation */}
        {workflowState === "awaiting_validation" &&
          <Alert className={classes.alertBtn} severity="info">
            L'établissement est en cours de validation par {appConfig.appName}
          </Alert>}

          <PaymentAction />



      </>}



    </div>


  )
}

export default Payment
