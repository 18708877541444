import React, { useContext, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import RegistrationContext from '../../registrationContext'
import Plan from "./Plan"
import { useLocation } from 'react-router-dom'
import { DynamicPlanI, RouteStateI } from '../../types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  }))


const Plans = ({ setDisabled }: { setDisabled: (b: boolean) => void }) => {
  const { infos, setInfos } = useContext(RegistrationContext)
  const { state } = useLocation<RouteStateI>()

  useEffect(() => {
    if (infos.location_plan.plan && infos.location_plan.priceCents) setDisabled(false)
  },[])

  const choosePlan = (planTitle: DynamicPlanI["name"], priceCents: DynamicPlanI["price_cents"]) => {
    // on clean les dates de la sponso au cas où l'utilisateur aurait commencé à choisir un plan et une sponso et serait revenu au plan bronze
    if (infos.location.sponsored_end_at) delete infos.location.sponsored_end_at;
    if (infos.location.sponsored_start_at) delete infos.location.sponsored_start_at;
    // on ajoute le plan selectionné
    setInfos({ ...infos, location_plan: { plan: planTitle, priceCents: priceCents } })
    setDisabled(false)
  }

  const deletePlan = () => {
    setInfos({ ...infos, location_plan: {} });
    setDisabled(true)
  };

  return (
    <div>
      {(state?.status !== "upgrade") &&
        <Plan
          deletePlan={deletePlan}
          selectedPlan={infos.location_plan?.plan}
          choosePlan={choosePlan}
          title="Bronze"
        />}

      {((state?.plan !== "Silver")) &&
        <Plan
          deletePlan={deletePlan}
          selectedPlan={infos.location_plan?.plan}
          choosePlan={choosePlan}
          title="Silver"
        />}
      <Plan
        deletePlan={deletePlan}
        selectedPlan={infos.location_plan?.plan}
        choosePlan={choosePlan}
        title="Gold"
      />
    </div>
  );
};

export default Plans;
