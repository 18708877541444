import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import GMap from "./GMap";
import RegistrationContext from "../../registrationContext";
import { useValidator } from "../../utils/useValidator";
import { useQuery } from "react-query";
import { fetchOne, fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { useQueryFromPath } from "../../utils/useQueryFromPath";
import { LocationInfoI } from "../../types";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(2),
    },
    form: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 600,
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(4),
    },
    mapContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),

      width: "100%",
      height: 520,
    },
    mapTypo: {
      marginBottom: theme.spacing(2),
    },
    category: {
      marginTop: theme.spacing(2),
    },
    space: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
  })
);

const LocationsEdit = () => {
  const classes = useStyles();
  const { accessToken } = useUserContext();
  const { errorMessage, setErrorMessage, checkField } = useValidator();
  const { infos, setInfos } = useContext(RegistrationContext);
  const locationSourceId = useQueryFromPath("location_source_id");

  const { status, data: results } = useQuery<{ result: LocationInfoI }>(
    ["locations", { id: locationSourceId }],
    () => {
      return fetchOne("locations", { id: locationSourceId }, accessToken);
    },
    { enabled: Boolean(locationSourceId) }
  );
  const revendicatedLocation = results?.result;

  useEffect(() => {
    setInfos({
      ...infos,
      location: {
        ...revendicatedLocation,
      },
    });
  }, [revendicatedLocation]);

  // fetch the categories
  const categoryFetchObject = {
    fields: { categories: ["name", "id"] },
    sort: "name",
    page: {
      number: 1,
      size: 100,
    },
  };
  const { data: categoryData } = useQuery<{
    results: { name: string; id: string }[];
    pageCount: number;
    total: number;
  }>(["categories", categoryFetchObject], () =>
    fetchList("categories", categoryFetchObject, accessToken)
  );
  const categories = categoryData?.results && categoryData?.results;
  const selectableCategories = [{ id: "0", name: "Pas de catégorie" }];
  if (categories) {
    categories.forEach((category) => selectableCategories.push(category));
  }
  const handleValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    switch (field) {
      case "name":
        setErrorMessage({ ...errorMessage, general: null, title: null });
        setInfos({
          ...infos,
          location: { ...infos.location, name: event.target.value },
        });
        break;
      case "description":
        setInfos({
          ...infos,
          location: { ...infos.location, description: event.target.value },
        });
        break;
      case "short_description":
        setInfos({
          ...infos,
          location: {
            ...infos.location,
            short_description: event.target.value,
          },
        });
        break;
      case "category_suggestion":
        setInfos({
          ...infos,
          location: {
            ...infos.location,
            category_suggestion: event.target.value,
          },
        });
        break;
      case "hours":
        setInfos({
          ...infos,
          location: { ...infos.location, hours: event.target.value },
        });
        break;
      case "phone":
        setInfos({
          ...infos,
          location: { ...infos.location, phone: event.target.value },
        });
        break;
      case "email":
        setErrorMessage({ ...errorMessage, general: null, email: null });
        setInfos({
          ...infos,
          location: { ...infos.location, email: event.target.value },
        });
        break;
      case "website":
        setErrorMessage({ ...errorMessage, general: null, web: null });
        setInfos({
          ...infos,
          location: { ...infos.location, website: event.target.value },
        });
        break;
      case "facebook":
        setInfos({
          ...infos,
          location: { ...infos.location, facebook: event.target.value },
        });
        break;
      case "legal_form":
        setInfos({
          ...infos,
          location: { ...infos.location, legal_form: event.target.value },
        });
        break;
      case "rccm_number":
        setInfos({
          ...infos,
          location: { ...infos.location, rccm_number: event.target.value },
        });
        break;
      case "latitude":
        setInfos({
          ...infos,
          location: { ...infos.location, latitude: event.target.value },
        });
        break;
      case "longitude":
        setInfos({
          ...infos,
          location: { ...infos.location, longitude: event.target.value },
        });
        break;
      default:
        break;
    }
  };

  const handleCategory = (
    event: React.ChangeEvent<{ value: any }>,
    i: number
  ) => {
    const categoryId = +(event.target.value as string);
    const newCategories = infos.location.categories
      ? [...infos.location.categories]
      : [];
    newCategories.splice(i, 1, categoryId ? { id: categoryId } : null);

    setInfos({
      ...infos,
      location: {
        ...infos.location,
        categories: [...newCategories.filter((el) => el != null)],
      },
    });
  };
  return (
    <form className={classes.form}>
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        onChange={(event) => handleValue(event, "name")}
        label={`Nom de l'établissment`}
        onBlur={(event) => checkField(event, "title")}
        required
        value={infos.location.name}
        error={errorMessage.title ? true : false}
        helperText={errorMessage.title && errorMessage.title}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        value={infos.location.short_description}
        onChange={(event) => handleValue(event, "short_description")}
        label={`Courte description`}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.description}
        onChange={(event) => handleValue(event, "description")}
        label={`Description plus longue`}
        multiline
        rows={4}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.legal_form}
        onChange={(event) => handleValue(event, "legal_form")}
        label={`Forme juridique de votre établissement`}
        rows={1}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.rccm_number}
        onChange={(event) => handleValue(event, "rccm_number")}
        label={`Numéro RCCM`}
        rows={1}
      />

      {[0, 1, 2].map((cat) => (
        <div key={`category_${cat}`}>
          <FormControl className={classes.category} required>
            <InputLabel id={`category-select-label`}>Catégorie</InputLabel>

            <Select
              labelId={`category-select-label`}
              id={`category-select`}
              value={
                (infos?.location?.categories &&
                  infos?.location?.categories[cat]?.id) ||
                0
              }
              onChange={(event) => handleCategory(event, cat)}
              disabled={
                infos?.location?.categories &&
                infos?.location?.categories.length < cat
              }
            >
              {selectableCategories.map((cat: any) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ))}

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.category_suggestion}
        onChange={(event) => handleValue(event, "category_suggestion")}
        label={`Suggérer une nouvelle catégorie`}
        rows={1}
      />

      <div className={classes.mapContainer}>
        <div className={classes.mapTypo}>
          <Typography color="textSecondary">Adresse</Typography>
          <Typography color="textSecondary" variant="caption">
            Cliquer sur la carte pour placer votre établissement
          </Typography>
        </div>

        <GMap infos={infos} setInfos={setInfos} />
      </div>
      {infos?.location?.address && (
        <Typography>{infos.location.address}</Typography>
      )}

      <div className={classes.space}></div>
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.hours}
        onChange={(event) => handleValue(event, "hours")}
        label={`Horaires`}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.phone}
        onChange={(event) => handleValue(event, "phone")}
        label={`Téléphone`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{`+${appConfig.phonePrefix}`}</InputAdornment>
          ),
        }}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.email}
        onChange={(event) => handleValue(event, "email")}
        label={`Email`}
        onBlur={(event) => checkField(event, "email")}
        error={errorMessage.email ? true : false}
        helperText={errorMessage.email && errorMessage.email}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.website}
        onChange={(event) => handleValue(event, "website")}
        label={`Site web`}
        type="url"
        onBlur={(event) => checkField(event, "web")}
        error={errorMessage.web ? true : false}
        helperText={errorMessage.web && errorMessage.web}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue=""
        value={infos.location.facebook}
        onChange={(event) => handleValue(event, "facebook")}
        label={`Page facebook`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">facebook.com/</InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default LocationsEdit;
