import { Paper, Typography, useMediaQuery } from "@material-ui/core";
import React, { useContext } from "react";
import RegistrationContext from "../../registrationContext";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import differenceInDays from "date-fns/differenceInDays";
import Checkbox from "@material-ui/core/Checkbox";
import Plan from "../shared/Plan";
import { useLocation } from "react-router-dom";
import { RouteStateI } from "../../types";
import { useQuery } from "react-query";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import CGU from "../../assets/ConditionsGénéralesAbonnementAuxServicesMTN-Innovasas.pdf"
import clsx from "clsx";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
    container: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 600,
      marginBottom: theme.spacing(4),
    },

    containerPrice: {
      padding: theme.spacing(4),
      display: 'flex',
      justifyItems: 'center',
    },
    col: {
      flexDirection: "column",
    },
    containerInfos: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    checkBox: {
      paddingTop: theme.spacing(1)
    }
  })
);

const Validation = ({ setDisabled }: { setDisabled: (b: boolean) => void }) => {
  const classes = useStyles();
  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { infos, setInfos } = useContext(RegistrationContext);

  const { pathname, state } = useLocation<RouteStateI>();
  const { accessToken } = useUserContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(!event.target.checked);
  };

  // on harcode le prix de la sponso !
  const sponsoDuration = differenceInDays(
    infos.location.sponsored_end_at,
    infos.location.sponsored_start_at
  );

  console.log(infos.location)
  // const sponsoPrice = () => {
  //   switch (sponsoDuration) {
  //     case 90:
  //       return 50000;
  //     case 30:
  //       return 25000;
  //     default:
  //       return 0;
  //   }
  // }

  const sponsoPrice = appConfig.sponsorship_options[sponsoDuration]

  const { data } = useQuery(
    [
      "locations",
      state?.locationId,
      `upgrade_plan?type=${infos.location_plan.plan}`,
    ],
    () => {
      if (state?.locationId) {
        return fetchOne(
          "locations",
          { id: state?.locationId },
          accessToken,
          `upgrade_plan?type=${infos.location_plan.plan}`
        );
      }

    },
    { enabled: Boolean(state?.status === "upgrade" && state?.locationId) }
  );
  const upgradePrice = data?.price;

  const totalPrice = () => {
    const totalPrice = (sponsoPrice ? sponsoPrice : 0) +
      (upgradePrice?.cents
        ? upgradePrice.cents
        : infos.location_plan.priceCents
          ? infos.location_plan.priceCents
          : 0)
    return totalPrice.toLocaleString('fr')
  }

  return (
    <>
      <div className={classes.container}>
        {infos.location_plan.plan && (
          <Paper className={classes.paper}>
            <div className={classes.containerInfos}>
              <Plan title={infos.location_plan.plan} />
            </div>
            <Typography variant="h5">
              {upgradePrice?.cents
                ? upgradePrice.cents
                : infos.location_plan.priceCents?.toLocaleString('fr')}{" "}
              FCFA
            </Typography>
          </Paper>
        )}

        {infos.location.sponsored_start_at && (
          <Paper className={classes.paper}>
            <div className={clsx(classes.containerInfos, downSm && classes.col)}>
              <Typography variant="h5">Emplacement sponsorisé</Typography>
              <Typography variant="h5">{sponsoDuration} jours</Typography>
            </div>
            <Typography variant="h5">{sponsoPrice?.toLocaleString('fr')} FCFA</Typography>
          </Paper>
        )}

        <div className={classes.containerPrice}>
          <Typography variant="h5">
            Total :{" "}
            {totalPrice()}{" "}
            FCFA
          </Typography>
        </div>
        <div className={classes.containerPrice}>
          <Checkbox
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            onChange={handleChange}
          />
          <div className={classes.checkBox}>
            {/* Pénible, puisqu'on a un pdf ou un site on check à l'arrache ici, idéalement tout avoir au même format */}
            J'accepte les  <a href={process.env.REACT_APP_CLIENT === "mtn-benin" ? CGU : "https://www.innovacontents.com/cgu-annuaire-zamani"} target="_blank" >conditions générales de vente</a>  du service annuaire de {appConfig.brand.name}
          </div>
        </div>
      </div>
    </>
  );
};
export default Validation;
