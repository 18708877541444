import React, { useContext, useState } from "react";
import {
  TextField,
  FormControl,
  IconButton,
  Input,
  InputAdornment, InputLabel, Typography
} from "@material-ui/core";
// import { validate } from "validate.js";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import { constraints } from "../utils/constraints";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import RegistrationContext from "../registrationContext";
import StepperLayout from "./StepperLayout";
import MainLayout from "./MainLayout";
import appConfig from "../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      maxWidth: 600,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      display: "flex",
      flexDirection: "column",
    },
    field: {
      margin: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      maxWidth: 600,
      width: "100%"
    },
    btnContainer: {
      textAlign: "center"
    },
    caption: {
      textAlign: "center",
    },
    tabs: {
      display: "flex",
      justifyContent: "space-evenly"
    },
    pwd: {
      display: "flex",
      flexDirection: "row"
    },
    margin: {
      margin: theme.spacing(2),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    typo: {
      fontSize: 18
    }
  })
);

type Props = {
  categories?: any[]
}

function SignUp({ categories }: Props) {

  const classes = useStyles();
  const [errors, setErrors] = useState({ emailAddress: "", password: "" });
  const { infos, setInfos } = useContext(RegistrationContext)

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <MainLayout>
    <StepperLayout activeStep={0}>
      <div className={classes.form}>
        {/* <Typography className={classes.typo}>
          Vous ne serez facturé du service qu'après la signature de votre contrat de service.
        </Typography>
        <br />
        <Typography className={classes.typo}>
          Un agent de l'Opérateur {appConfig.appName} Bénin prendra contact avec vous dès réception de votre fiche de souscription.
        </Typography>
 */}
        <TextField
          id="name"
          value={infos.user.name}
          onChange={({ currentTarget }) =>
            setInfos({ ...infos, user: { ...infos.user, name: currentTarget.value} })
          }
          label="Nom"
          className={classes.field}
        />
        <TextField
          error={errors["emailAddress"] ? true : false}
          id="emailAdress"
          value={infos.user.email}
          onChange={({ currentTarget }) =>
            setInfos({ ...infos, user: { ...infos.user, email: currentTarget.value} })
          }
          helperText={errors["emailAddress"] ? errors.emailAddress[0] : false}
          label="Adresse email"
          className={classes.field}
        />

        <TextField
          id="phone"
          value={infos.user.phone}
          onChange={({ currentTarget }) =>
            setInfos({ ...infos, user: { ...infos.user, phone: currentTarget.value} })
          }
          label="Numéro de téléphone"
          className={classes.field}
          InputProps={{
            startAdornment: <InputAdornment position="start">+{appConfig.phonePrefix}</InputAdornment>,
          }}
        />

        <FormControl className={clsx(classes.margin)}>
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={infos.user.password}
            onChange={({ currentTarget }) =>
            setInfos({ ...infos, user: { ...infos.user, password: currentTarget.value} })
          }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

      </div>
    </StepperLayout>
    </MainLayout>
  );
}
export default SignUp;
