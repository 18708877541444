import {
  Typography,
  TextField,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import { createOne } from "../../API";
import { useUserContext } from "../../authentication";
import { useLocationId } from "../../utils/useLocationId";
import { useLocationInfos } from "../../utils/useLocationInfos";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateOne } from "../../API";
import { fetchList } from "../../API";
import { Salesperson } from "../../types";
import Alert from "@material-ui/lab/Alert";
import { useConfigurationContext } from "../../configurationContext";
import { actionate } from "../../API";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    space: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    checkbox: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },

    alertBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    associateBtn: {
      width: "50%",
      marginTop: theme.spacing(2),
    },
  })
);

const PaymentAction = () => {
  const classes = useStyles();
  const id = useLocationId();
  const configuration = useConfigurationContext();
  const { toBePayed, payments, location } = useLocationInfos(id);
  const { accessToken } = useUserContext();
  const [isPayed, setIsPayed] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const [commercial, setCommercial] = useState("none");
  const [momo, setMomo] = useState({});


  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: "momo_transaction_id" | "momo_phone_used"
  ) => {
    switch (type) {
      case "momo_transaction_id":
        setMomo({ ...momo, momo_transaction_id: e.target.value });
        break;
      case "momo_phone_used":
        setMomo({ ...momo, momo_phone_used: e.target.value });
        break;
      default:
        break;
    }
  };

  const handlePayment = async () => {
    if (toBePayed && toBePayed[0]?.payment?.id) {
      const response = await createOne(
        "payments",
        { attributes: momo },
        accessToken,
        "send_codes",
        toBePayed[0]?.payment.id
      );
      handleAssociate()
      setIsPayed(response.ok);
      if (!response.ok) {
        const res = await response.json();
      }
    }
  };

  /////////////Comercials
  ///////////////////////

  const [isChecked, setIsChecked] = useState(false);
  const queryClient = useQueryClient();
  const authentication = useUserContext();
  const [isSaved, setIsSaved] = useState<"saved" | "not saved" | "">();

  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const handleCommercials = (event: any) => {
    setCommercial(event.target.value as string);
  };

  ///// Fetch des commerciaux
  const fetchObject = {};

  const { data } = useQuery<{
    results: Salesperson[];
  }>(
    ["salespeople", fetchObject],
    () => fetchList("salespeople", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        authentication.logOut();
      },
    }
  );

  /////// Associer a products un commercial


  const [saved, setSaved] = useState(false)

  // const com=JSON.parse(commercial)
  

  const productId = toBePayed && toBePayed.map(x=>{
    if (x.id){return x.id}
  })

  // console.log(toBePayed)

  const handleAssociate = async () => {
    if (commercial !== "none") {
      toBePayed && toBePayed.forEach(tbp=>{
        actionate({
          action: "update_salesperson",
          model: "products",
          id: tbp.id,
          salespersonId: commercial,
          method:"PATCH",
          accessToken: accessToken || ""
        });
      })
      // productId && productId?.forEach(async (pId) => {
      //   const response = await actionate({
      //     action: "update_salesperson",
      //     model: "products",
      //     id: pId,
      //     salespersonId: commercial,
      //     method:"PATCH",
      //     accessToken: accessToken || ""
      //   });  
      // })
    }
  };
 
  return (
    <div>
      {payments &&
        payments.map((payment, i: number) => (
          <div key={payment?.id}>
            {payments?.length > 1 && (
              <Typography className={classes.space}>
                Paiement n°{i + 1}
              </Typography>
            )}
            {/* si le paiement est en attente de validation */}
            {payment?.workflow_state === "awaiting_validation" && (
              <Alert className={classes.alertBtn} severity="info">
                Votre paiement est en cours de validation par {appConfig.appName}
              </Alert>
            )}

            {/* si le paiement est en attente de validation */}
            {isPayed && (
              <Alert className={classes.alertBtn} severity="success">
                Votre paiement a été pris en compte. Il est maintenant en cours
                de validation par {appConfig.appName}
              </Alert>
            )}

            <Typography variant="h6" color="textSecondary">
              Total
            </Typography>
            {payment && <Typography>{payment?.displayed_price}</Typography>}

            {/* si le paiement est à effectuer */}
            {!isPayed &&
              location?.workflow_state !== "awaiting_validation" &&
              toBePayed &&
              toBePayed[0]?.payment?.workflow_state === "awaiting_payment" && (
                <>
                  <Typography
                    className={classes.alertBtn}
                    variant="h6"
                    color="textSecondary"
                  >
                    Payer
                  </Typography>

                  <Typography>
                  {process.env.REACT_APP_CLIENT === "mtn-benin" && `Le numéro MoMoPay a utiliser pour votre paiement est le ${configuration?.momocode}`}
                  {process.env.REACT_APP_CLIENT === "zamani" && `Veuillez compléter vos références de transaction`}
                    
                  </Typography>
                  <form className={classes.form} noValidate autoComplete="off">
                    <TextField
                      onChange={(e) => handleChange(e, "momo_transaction_id")}
                      className={classes.space}
                      id="transaction_id"
                      label={process.env.REACT_APP_CLIENT === "mtn-benin" ? "Code MoMoPay" : "Veuillez renseigner votre référence de compte client commençant par 'PCXXXXXX'"}
                    />
                    <TextField
                      onChange={(e) => handleChange(e, "momo_phone_used")}
                      className={classes.space}
                      id="reference_number"
                      // label="N° de téléphone de la transaction"
                      label={process.env.REACT_APP_CLIENT === "mtn-benin" ? "N° de téléphone de la transaction" : "Veuillez renseigner le numéro de la facture"}
                    />

                    <Button
                      onClick={() => handlePayment()}
                      className={classes.alertBtn}
                      variant="contained"
                      color="secondary"
                    >
                      Payer
                    </Button>

                    <div className={classes.checkbox}>
                      <Checkbox
                        checked={isChecked}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        onChange={handleCheckBox}
                      />
                      <Typography className={classes.space}>
                        Souhaitez-vous sélectionner un contact commercial ?
                      </Typography>
                    </div>

                    {isChecked && (
                      <>
                        <div className={classes.space}>
                          <InputLabel id="commercial-select-label">
                            Commercial
                          </InputLabel>
                          <Select
                            labelId="commercial-select-label"
                            id="commercial-simple-select"
                            value={commercial}
                            label="Commercial"
                            onChange={handleCommercials}
                          >
                            <MenuItem value="none" key="none">
                                Aucun
                              </MenuItem>
                            {data?.results.map((x) => (
                              <MenuItem value={x.id} key={x.id}>
                                {x.last_name} {x.first_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>

                        {/* <Button
                          variant="contained"
                          color="secondary"
                          className={classes.associateBtn}
                          // onClick={handleAssociate}
                        >
                          Associer le comerciale
                        </Button> */}
                        {isSaved === "saved" && (
                          <Alert
                            severity="success"
                            className={classes.alertBtn}
                          >
                            Les modifications ont été enregistrées
                          </Alert>
                        )}
                      </>
                    )}

                    {pathname === "/payment" && (
                      <Button
                        variant="outlined"
                        onClick={() => history.push("/")}
                      >
                        Mon espace
                      </Button>
                    )}
                  </form>
                </>
              )}
          </div>
        ))}
    </div>
  );
};

export default PaymentAction;
