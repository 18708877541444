import React from 'react'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Container, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from 'clsx';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 100,
      paddingLeft: 0,
      paddingRight: 0

    },
    paper: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),

      marginBottom: theme.spacing(6),
      minHeight: "70vh",
      position: "relative"
    },
    sm: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

    }
  })
);

const MainLayout = ({ children }: any) => {
  const classes = useStyles()
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className={classes.container} maxWidth="md">
      <Paper className={clsx(classes.paper, sm && classes.sm)}>
        {children}
      </Paper>
    </Container>
  )
}

export default MainLayout
