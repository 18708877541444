import { Button, Chip, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../authentication";
import ConnectedLayout from "./shared/ConnectedLayout";
import Loader from "./shared/Loader";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RegistrationContext from "../registrationContext";
import MainLayout from "./MainLayout";
import { useLocationInfos } from "../utils/useLocationInfos";
import { useLocationId } from "../utils/useLocationId";
import Payment from "./shared/Payment";
import { plainTextDate } from "../utils/formatDate";
import Plan from "./shared/Plan";
import { OwnerI, ProductI } from "../types";
import { isAfter } from "date-fns";
import ProductsHistory from "./ProductsHistory";
import { actionate, fetchOne } from "../API";
import Alert from "@material-ui/lab/Alert";
import { useQuery, useQueryClient } from "react-query";
import clsx from "clsx";
import CustomButton from "./shared/CustomButton";
import appConfig from "../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
      maxWidth: 600,
    },
    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    container: {
      maxWidth: 600,
      margin: "auto",
    },
    spaceY2: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
    title: {
      paddingTop: theme.spacing(3),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    textField: {
      // marginTop: theme.spacing(2),
    },
    containerField: {
      // width: "60%",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
      maxWidth: 600,
      marginRight: "auto",
      marginLeft: "auto"


    },
    ship: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      paddingTop: 30,
      paddingBottom: 30,
    },
    bold: {
      fontWeight: "bold",
    }
  })
);

const ActivePayment = ({ activeProduct }: { activeProduct: ProductI }) => {
  return (
    <>
      <Typography>Id de la transaction MoMoPay:</Typography>
      <Typography>{activeProduct.payment?.momo_transaction_id}</Typography>
      <Typography>Téléphone utilisé:</Typography>
      <Typography>{activeProduct.payment?.momo_phone_used}</Typography>
    </>
  );
};

const OneLocation = () => {
  const classes = useStyles();
  const { infos, setInfos } = useContext(RegistrationContext);
  const history = useHistory();
  const id = useLocationId();
  const {
    status,
    location,
    toBePayed,
    activeProducts,
    activeSponso,
  } = useLocationInfos(id);
  const { accessToken, user } = useUserContext();

  // ON RESET L'OBJET INFOS
  useEffect(() => {
    setInfos({
      location: {},
      location_plan: {},
      user: {},
    });
  }, []);

  // const [isSponsoFinish, setIsSponsoFinish] = useState(false)
  const renewPlan = (activeProduct: ProductI) => {
    // on vérifie que le product est un plan
    if (
      activeProduct?.relation_value === "Gold" ||
      activeProduct?.relation_value === "Silver"
    ) {
      // on set le plan dans l'objet pour la requête
      setInfos({
        ...infos,
        location_plan: { plan: activeProduct?.relation_value },
      });

      // on regarde si sa date de fin de la sponso est passée
      let isSponsoFinish = false;
      if (activeSponso?.date_end) {
        isSponsoFinish = isAfter(
          Date.parse(activeSponso?.date_end),
          new Date()
        );
      }
      // si la sponso est finie on envoie à la page de validation, sinon on envoie à la page de sponso
      if (!isSponsoFinish) {
        history.push("/sponsorisation", {
          plan: activeProduct?.relation_value,
          status: "renewal",
          name: location?.name,
          locationId: id,
        });
      } else {
        history.push("/validation", {
          plan: activeProduct?.relation_value,
          status: "renewal",
          name: location?.name,
          locationId: id,
        });
      }
    }
  };

  /////////////////////////////////////////////////////////////////////////
  ////////////////// Add et remove Owner pour les plans Gold/////////////////////////
  /////////////////////////////////////////////////////////////////////////

  const [owner, setOwner] = useState({ email: "" });
  const [isAdd, setisAdd] = useState(false);
  const [errorMail, setErrorMail] = useState<string | null>(null);
  const [isOwnerLoading, setIsOwnerLoading] = useState(false)
  const [trigger, setTrigger] = useState(false)



  const handleValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    switch (field) {
      case "email":
        setOwner({ ...owner, email: event.target.value });
        break;
      default:
        break;
    }
  };

  const addOwner = async () => {
    // si le mail existe est déjà celui d'un des owner on empêche de l'ajouter
    const checkMail = location?.owners?.find((x: OwnerI) => owner.email === x.email)

    if (checkMail) {
      setErrorMail("Le compte que vous essayez d'ajouter est déjà administrateur de cet établissment")
      return
    }


    if (owner.email === "") {
      setErrorMail("Vous n'avez entré aucun email")
      setTimeout(() => {
        setErrorMail("")
      }, 2500);
      return
    }
    setIsOwnerLoading(true)
    try {
      const response = await actionate({
        id: location?.id || "",
        action: "add_owner",
        email: encodeURIComponent(owner.email),
        accessToken: accessToken || "",
        model: "locations"
      });
      if (response) {
        setisAdd(true);
        setIsOwnerLoading(false)
        setTrigger(true)

        // await queryClient.invalidateQueries("locations");
        // await queryClient.refetchQueries();

        setTimeout(() => {
          setisAdd(false);
          setOwner({ ...owner, email: "" });
        }, 2500);
      }
    } catch (e) {
      setIsOwnerLoading(false)

      if (e.status === 422) {
        setErrorMail(e.text);
        setTimeout(() => {
          setErrorMail(null);
        }, 2500);
      }
    }
  };
  

  const { data: results } = useQuery(["locations", { id: id, include: ["products.payment", "plan", "owners"] }], () => {
    if (id) {
      setTrigger(false)
      return fetchOne("locations", { id: id, include: ["products.payment", "plan", "owners"] }, accessToken)
    }
  },
    {
      enabled: Boolean(id && trigger),
    });



  const removeOwner = async (email: string) => {
    const response = await actionate({
      id: location?.id || "",
      action: "remove_owner",
      email,
      accessToken: accessToken || "",
      model: "locations"
    });
    if (response) {
      setTrigger(true)
      // await queryClient.invalidateQueries("locations");
      // await queryClient.refetchQueries(['locations'], { active: true });
    }
  };



  return (
    <>
      <MainLayout>
        {status === "loading" && <Loader />}
        {status === "success" && location && (
          <ConnectedLayout name={location.name}>
            {location?.plan?.name === "Bronze" && location?.workflow_state === "awaiting_validation" && <Alert className={classes.alert} severity="info">L'établissement est en cours de validation par {appConfig.appName}
            </Alert>}
            <div className={classes.container}>
              {toBePayed && (
                <Payment
                  workflowState={location.workflow_state}
                  toBePayed={toBePayed}
                />
              )}

              {location?.workflow_state === "validated" &&
                activeProducts &&
                activeProducts?.length > 0 && (
                  <div>
                    <Typography className={classes.subtitle} variant="h5">
                      Plan Actuel
                    </Typography>

                    {/*  AFFICHAGE DU OU DES CODES MOMOPAYS */}

                    {/* S'il y a un paiement différent pour la sponso et le plan on affiche les deux */}
                    {activeProducts.length > 1 &&
                      activeProducts[0].payment?.id !==
                      activeProducts[1].payment?.id ? (
                      <div>
                        <Typography
                          color="textSecondary"
                          className={classes.spaceY2}
                          variant="h6"
                        >
                          Dernières transactions
                        </Typography>
                        <Typography className={clsx(classes.spaceY2, classes.bold)}>
                          {activeProducts[0].relation_type !== "add_sponso"
                            ? "Plan"
                            : "Sponsorisation:"}
                        </Typography>
                        <ActivePayment activeProduct={activeProducts[0]} />
                        <Typography className={clsx(classes.spaceY2, classes.bold)}>
                          {activeProducts[1].relation_type !== "add_sponso"
                            ? "Plan:"
                            : "Sponsorisation"}
                        </Typography>
                        <ActivePayment activeProduct={activeProducts[1]} />
                      </div>
                    ) : (
                      <>{activeProducts[0].relation_value !== "Bronze" && <>
                        <Typography
                          color="textSecondary"
                          className={classes.spaceY2}
                          variant="h6"
                        >
                          Dernière transaction
                        </Typography>
                        <ActivePayment activeProduct={activeProducts[0]} />
                      </>}
                      </>
                    )}
                    {/* BOUCLE SUR LES PRODUCTS ACTIFS */}
                    {activeProducts.map((activeProduct) => (
                      <div key={activeProduct.id}>
                        <Typography
                          color="textSecondary"
                          className={classes.spaceY2}
                          variant="h6"
                        >
                          {activeProduct.relation_type === "add_sponso"
                            ? "Sponsorisation"
                            : "Plan"}
                        </Typography>
                        {activeProduct.relation_type !== "add_sponso" && (
                          <Typography>
                            Plan {activeProduct.relation_value}
                          </Typography>
                        )}
                        {activeProduct.duration_in_months && (
                          <Typography>
                            Durée {activeProduct.duration_in_months} mois
                          </Typography>
                        )}
                        {/* {activeProduct.date_start && <Typography>Début: {format(Date.parse(activeProduct.date_start), 'dd MMM yyyy', { locale: frenchLocale })}</Typography>} */}
                        {activeProduct.date_end && (
                          <Typography>
                            Fin: {plainTextDate(activeProduct.date_end)}
                          </Typography>
                        )}
                        {activeProduct.salesperson?.first_name && activeProduct.salesperson?.last_name && (
                          <Typography>Commercial: {activeProduct.salesperson.first_name} {activeProduct.salesperson.last_name}</Typography>
                        )}


                        {(activeProduct.relation_value === "Bronze" ||
                          activeProduct.relation_value === "Silver" ||
                          activeProduct.relation_value === "Gold") && (
                            <Plan title={activeProduct.relation_value} />
                          )}
                      </div>
                    ))}

                    {activeProducts.map((activeProduct) => (
                      <div key={activeProduct.id} className={classes.spaceY2}>
                        {/* BOUTONS D'ACTIONS POUR LES PLANS */}
                        {(!toBePayed || toBePayed?.length === 0) &&
                          activeProduct?.relation_type !== "add_sponso" && (
                            <div>
                              <div className={classes.btnContainer}>
                                {(activeProduct?.relation_value === "Silver" ||
                                  activeProduct?.relation_value ===
                                  "Bronze") && (
                                    <Button
                                      onClick={() =>
                                        history.push("/plan", {
                                          plan: activeProduct.relation_value,
                                          status: "upgrade",
                                          name: location.name,
                                          locationId: id,
                                          isSponso: activeSponso ? true : false,
                                        })
                                      }
                                      className={classes.button}
                                      variant="outlined"
                                      disabled={
                                        activeProduct.workflow_state !== "active"
                                      }
                                    >
                                      Changer mon offre
                                    </Button>
                                  )}
                                 <Button
                                  onClick={() => renewPlan(activeProduct)}
                                  className={classes.button}
                                  variant="outlined"
                                  disabled={
                                    activeProduct.workflow_state !== "active"
                                  }
                                >
                                  Renouveler mon offre
                                </Button>
                              </div>

                              {/* BOUTON D'ACTION POUR LA SPONSO */}
                              {!activeSponso && activeProduct?.relation_value !=="Bronze" && (
                                <>
                                  <Typography
                                    className={classes.subtitle}
                                    variant="h5"
                                  >
                                    Emplacement Sponsorisé
                                  </Typography>

                                  <div className={classes.btnContainer}>
                                    <Button
                                      onClick={() =>
                                        history.push("/sponsorisation", {
                                          status: "sponso",
                                          name: location.name,
                                          locationId: id,
                                        })
                                      }
                                      className={classes.button}
                                      variant="outlined"
                                    >
                                      Option Emplacement Sponsorisé
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              {location.plan &&
                location.plan.name === "Gold" && location?.owners &&
                location?.owners?.length < 3 && (
                  <>
                    <form className={classes.containerField}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        variant="h6"
                      >
                        Ajouter un administrateur à votre établissement Gold
                      </Typography>

                      <TextField
                        className={classes.textField}
                        required
                        onChange={(event) => handleValue(event, "email")}
                        label="Email"
                        value={owner.email}
                      />
                    </form>

                    {isAdd && (
                      <Alert className={classes.alert} severity="success">
                        Un nouvel administrateur a bien été ajouté.
                        <br />
                        S'il n'apparaît pas tout de suite, rechargez votre navigateur.
                      </Alert>
                    )}
                    {errorMail && (
                      <Alert className={classes.alert} severity="error">
                        {errorMail}
                      </Alert>
                    )}

                    <div className={classes.btnContainer}>
                      <CustomButton
                        accent="main"
                        submitFn={() => addOwner()}
                        className={classes.button}
                        isLoading={isOwnerLoading}
                      >
                        Ajouter un administrateur
                      </CustomButton>
                    </div>
                  </>
                )}

              {location.plan &&
                location.plan.name === "Gold" && location?.owners &&
                location.owners.length > 1 && (
                  <form className={classes.containerField}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      variant="h6"
                    >
                      Enlever un administrateur à votre établissement Gold
                    </Typography>

                    <div className={classes.ship}>
                      {location?.owners && location.owners
                        .filter((owner: any) => owner.email !== user?.email)
                        .map((owner: any) => (
                          <Chip
                            key={owner.id}
                            label={owner.email}
                            onDelete={() =>
                              removeOwner(encodeURIComponent(owner.email))
                            }
                            color="primary"
                          />
                        ))}
                    </div>
                  </form>
                )}
            </div>
          </ConnectedLayout>
        )}
      </MainLayout>
      <ProductsHistory />
    </>
  );
};

export default OneLocation;

