import { uniqBy } from "lodash";
import { useQuery } from "react-query";
import { fetchOne } from "../API";
import { useUserContext } from "../authentication";
import { LocationInfoI } from "../types";
import { useImage } from "./firebase";

interface productsInfosToSendI {
  type: "product",
  id: string,
}

export const useLocationInfos = (id: string |undefined ) => {
  const authentication = useUserContext()
  const { status, data: results } = useQuery(["locations", { id: id, include: ["products.payment", "plan","owners", "products"] }], () => {
    
    if (id) {
      return fetchOne("locations", { id: id, include: ["products.payment", "plan", "owners", "products"] }, authentication.accessToken)
    }
  }, 
  {
    enabled: Boolean(id),
  });
  const location: LocationInfoI = results?.result && results?.result

  const imageUrl = useImage(location?.images ? location?.images[0] : "")

  const planDateEnd = location?.location_plans && new Date(location?.location_plans[0].plan_date_end)


  // On trie les les établissements en attente de paiment
  const toBePayed = location?.products?.filter(product => product.workflow_state === "awaiting_payment")

  // On vérifie qu'il n'y a qu'un seul paiement à effectuer
  let payments
  // let toBeValidatedPayment
  if (toBePayed && toBePayed.length !== 0 && toBePayed[0].payment) {
    const uniqToBePayed = uniqBy(toBePayed, "payment.id")
    payments = uniqToBePayed.map((tbp) => {
      if (tbp.payment !== undefined) {
        return { ...tbp.payment }
      }
    })
    // if (payments && payments.length !== 0) {
    //   toBeValidatedPayment = payments.filter(payment => payment?.workflow_state === "awaiting_validation")[0]
    // }
  }

  // On filtre les produits payés
  const activeProducts = location?.products?.filter(product => product.workflow_state === "active")
  
  // On filtre les paiements reliés produits actif
  let activePayments
  if (activeProducts && activeProducts.length !== 0 && activeProducts[0].payment) {
    const uniqActiveProducts = uniqBy(activeProducts, "payment.id")
    activePayments = uniqActiveProducts.map((uniqActiveProduct) => {
      if (uniqActiveProduct.payment !== undefined) {
        return { ...uniqActiveProduct }
      }
    })
  }

  // on filtre la sponso active
  const activeSponso = activeProducts?.find((ap) => ap.relation_type === "add_sponso")

  // On trie les les établissements payés
  const paidProducts = location?.products?.filter(product => product.workflow_state === "paied")

  // On trie les les établissements inactif
  const inactiveProducts = location?.products?.filter(product => product.workflow_state === "inactive")


  return {
    location,
    imageUrl,
    planDateEnd,
    status,
    toBePayed: toBePayed && toBePayed?.length > 0 && toBePayed,
    payments,
    activeProducts,
    activePayments,
    activeSponso,
    paidProducts: paidProducts && paidProducts?.length > 0 && paidProducts,
    inactiveProducts: inactiveProducts && inactiveProducts?.length > 0 && inactiveProducts,
    // toBeValidatedPayment: toBeValidatedPayment
  }
}