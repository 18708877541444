// Contraintes pour validate.js

export const loggerConstraints = {
  emailAddress: {
    presence: {
      allowEmpty: false,
      message: "^Entrez une adresse email"
    },
    email: {
      message: "^Entrez une adresse email valide"
    }
  },
};

export const constraints = {
  emailAddress: {
    presence: {
      allowEmpty: false,
      message: "^Entrez une adresse email"
    },
    email: {
      message: "^Entrez une adresse email valide"
    }
  }
};

export const textConstraints = {
  text: {
    presence: {
      allowEmpty: false,
      message: "^Ce champ ne peut pas être vide"
    },
  },
}

export const isEmptyConstraint = {
  isEmpty: {
    presence: {
      allowEmpty: false,
      message: "^can be empty"
    }
  }
}

export const urlConstraints = {
  web: {
    url: {
      schemes: ["http", "https", "ftp"],
      message: `^L'url  est incorrecte (indice: elle doit commencer par "http://" ou "https://")`
    },
  },
}

export const emailConstraints = {
  mail: {
    email: {
      message: "^Le mail n'est pas valide",
    },
  },

}
