import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useNavigation } from "../../utils/useNavigation";
import { createOne, actionate } from "../../API";
import RegistrationContext from "../../registrationContext";
import { ActionI, RouteStateI } from "../../types";
import { useUserContext } from "../../authentication";
import { useValidator } from "../../utils/useValidator";
import { Alert } from "@material-ui/lab";
import { useSponso } from "../../utils/useSponso";
import { useQueryFromPath } from "../../utils/useQueryFromPath";
import CustomButton from "./CustomButton";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnContainer: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
    btn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      maxWidth: 600,
      width: "100%",
    },
    alert: {
      margin: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      maxWidth: 600,
      width: "100%",
    },
  })
);

type Props = { activeStep?: number; state?: any; disabled?: boolean };

const PrevNextButton = ({ activeStep, disabled = false }: Props) => {
  const classes = useStyles();
  const { pathname, state } = useLocation<RouteStateI>();
  const history = useHistory();
  const { goNext, goPrev, serverError, isLoading } = useNavigation();
  const { infos } = useContext(RegistrationContext);
  const [action, setAction] = useState<ActionI>();
  const { accessToken } = useUserContext();
  const { errorMessage } = useValidator();
  // on définit l'action de sponso
  const sponsoType = useSponso(
    infos.location.sponsored_end_at,
    infos.location.sponsored_start_at
  );
  const locationSourceId = useQueryFromPath("location_source_id");

  useEffect(() => {
    if (state?.status) {
      // on définit l'action sur la plan
      switch (state?.status) {
        case "firstLocation":
          setAction("first_plan");
          break;
        case "newLocation":
          setAction("first_plan");
          break;
        case "upgrade":
          // s'il n'y en a pas de plan, on en ajoute un
          if (!state?.plan) {
            setAction("first_plan");
          }
          // si le plan est bronze ou gold, on l'upgrade
          if (state?.plan) {
            setAction("upgrade_plan");
          }
          break;
        case "renewal":
          setAction("renew_plan");
          break;
        default:
          break;
      }
    } else if (locationSourceId) {
      setAction("first_plan");
    }
  }, [locationSourceId]);

  const formatValue = (rawValue: any) => {
    const { id, owners, products, categories, ...attributes } = rawValue;
    if (attributes.facebook) {
      attributes.facebook = attributes.facebook
        .split("/")
        .filter((e: string) => e !== "")
        .slice(-1)[0];
    }
    if (attributes.phone) {
      attributes.phone = `+${appConfig.phonePrefix + attributes.phone}`;
    }
    const formattedValue = {
      // type: "articles",
      attributes: { ...attributes },
      ...(id && { id }),
      relationships: {
        ...(categories && {
          categories: {
            data: categories.map((category: any) => ({
              type: "categories",
              id: category.id,
            })),
          },
        }),
      },
    };
    return formattedValue;
  };

  const handleProductsAndPayment = async (locationId: string) => {
    if (accessToken) {
      // ON ENREGISTRE LE PLAN
      let planResponse;
      if (state?.status !== "sponso") {
        planResponse = await actionate({
          action,
          type: infos.location_plan.plan,
          id: locationId,
          accessToken,
          model: "locations",
        });
      }
      // ON ENREGISTRE LA SPONSO
      let sponsoResponse;
      if (sponsoType) {
        sponsoResponse = await actionate({
          action: "add_sponso",
          type: sponsoType.toString(),
          id: locationId,
          accessToken,
          model: "locations",
        });
      }

      // ON CRÉE LE PAIEMENT
      if (
        (planResponse?.ok && !sponsoType) ||
        (planResponse?.ok && sponsoResponse?.ok) ||
        (state?.status === "sponso" && sponsoResponse?.ok)
      ) {
        let productsInfosToSend = [];
        if (sponsoResponse) {
          const sponso = await sponsoResponse.json();
          productsInfosToSend.push({ type: "product", id: sponso.data.id });
        }
        if (planResponse) {
          const plan = await planResponse.json();
          productsInfosToSend.push({ type: "product", id: plan.data.id });
        }

        // const productsInfosToSend = [{ type: "product", id: tbp.id }]
        const payementResponse = await createOne(
          "payments",
          { attributes: {}, relationships: { products: productsInfosToSend } },
          accessToken
        );
        return payementResponse;
      }
    }
  };

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<string>();
  const validation = async () => {
    setIsValidating(true);
    // SI C'EST UN NOUVEL ÉTABLISSEMENT
    if (
      state?.status === "firstLocation" ||
      state?.status === "newLocation" ||
      locationSourceId
    ) {
      // 2. ON ENREGISTRE L'ÉTABLISSEMENT
      const locResponse = await createOne(
        "locations",
        formatValue(infos.location),
        accessToken,
        locationSourceId ? "claim" : "submit",
        locationSourceId && infos.location.id
      );

      if (locResponse && locResponse.ok) {
        const { data } = await locResponse.json();
        if (data.id && accessToken) {
          const response = await handleProductsAndPayment(data.id);
          if (response?.ok) {
            // ON ENVOIE LA PAGE SUIVANTE
            history.push("/resume", state);
          } else {
            setIsValidating(false);

            setValidationError(
              "une erreur s'est produite, veuillez recommencer l'opération"
            );
          }
        } else {
          setIsValidating(false);

          setValidationError(
            "une erreur s'est produite, veuillez recommencer l'opération"
          );
        }
      } else {
        setIsValidating(false);

        setValidationError(
          "une erreur s'est produite, veuillez recommencer l'opération"
        );
      }
    }

    if (
      state?.status === "renewal" ||
      state?.status === "sponso" ||
      state?.status === "upgrade"
    ) {
      if (state?.locationId) {
        const response = await handleProductsAndPayment(state?.locationId);
        if (response?.ok) {
          // ON ENVOIE LA PAGE SUIVANTE
          history.push("/payment", state);
        } else {
          setIsValidating(false);
          setValidationError(
            "une erreur s'est produite, veuillez recommencer l'opération"
          );
        }
      }
    }
  };

  return (
    <div className={classes.btnContainer}>
      {serverError && (
        <Alert className={classes.alert} severity="warning">
          {serverError === "Email n'est pas disponible"
            ? "Cet email n'est pas disponible"
            : serverError}
        </Alert>
      )}
      {pathname !== "/payment" && (
        <div>
          {/* {activeStep !== 0 &&  */}
          {activeStep !== 0 && activeStep !== 1 && (
            <Button
              variant="outlined"
              onClick={() => goPrev()}
              className={classes.btn}
            >
              Retour
            </Button>
          )}

          {pathname === "/validation" ? (
            <div>
              <CustomButton
                className={classes.btn}
                accent="main"
                submitFn={() => validation()}
                disabled={disabled}
                isLoading={isValidating}
              >
                Valider
              </CustomButton>
              {validationError && (
                <Alert className={classes.alert} severity="warning">
                  {validationError}
                </Alert>
              )}
            </div>
          ) : (
            <>
              {!infos?.location.name === true && pathname === "/infos" && (
                <Alert className={classes.alert} severity="warning">
                  l'établissement doit avoir un nom
                </Alert>
              )}
              <CustomButton
                className={classes.btn}
                accent="main"
                isLoading={isLoading}
                disabled={
                  disabled || (!infos?.location.name && pathname === "/infos")
                }
                submitFn={() => goNext()}
              >
                Suivant
              </CustomButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PrevNextButton;
