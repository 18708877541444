import React from 'react';
import { Node, Text as SlateText } from 'slate';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacingSmall: {
    },
    list: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold
    },
    del: {
      textDecoration: "line-through"
    },
    white: {
      color: "white"
    },
    optionContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)

    },

  })
);


const RichTextBuilder = ({ nodes }: { nodes: Node[] }) => {
  const classes = useStyles();

  const serialize = (node: Node) => {
    ;

    // Fonction pour rajouter des paragraphes autour des groupes de text au sein d'un noeud
    // Mais pas autour des noeuds non-text dans ce même noeud parent (tu comprends ?)
    const groupByType = (children: Node[]) => {
      const array: Array<Node | Node[]> = [];
      let counter = 0;
      children.forEach((node) => {
        // Si c'est un noeud de texte
        if (SlateText.isText(node)) {
          // Si on est déjà en train de faire un pack de text
          const current = array[counter];
          if (current && Array.isArray(current)) {
            current.push(node);
            // Sinon on va le créer
          } else {
            // Si l'index est déjà pris : on avance,
            if (current) {
              counter++;
            }
            //puis on crée
            array[counter] = [node];
          }
        } else {
          array.push(node);
          counter++;
        }
      });
      return array.map((item) =>
        Array.isArray(item) ? (
          <Typography>{item.map((subchild) => serialize(subchild))}</Typography>
        ) : (
          serialize(item)
        ),
      );
    };

    if (SlateText.isText(node)) {
      return <span className={clsx({
        [classes.bold]: node.bold,
        [classes.del]: node.del,
      })}>{node.text}</span>;

    }

    const children =
      node.type && node.type === 'list-item'
        ? groupByType(node.children)
        : node.children.map((n: Node) => serialize(n));

    switch (node.type) {
      case 'quote':
        return (
          <Typography className={classes.spacingSmall}>{children}</Typography>
        );
      case 'Typography':
        return (
          <Typography className={classes.spacingSmall}>{children}</Typography>
        );
      case 'bulleted-list':
        return <div className={classes.spacingSmall}>{children}</div>;
      case 'list-item':
        return (
          <div
            className={classes.optionContainer}>
            <CheckIcon classes={{ root: classes.white }} />
            <Typography>{children}</Typography>
          </div>
        );
      default:
        return (
          <Typography className={classes.spacingSmall}>{children}</Typography>
        );
    }
  };

  return <div>{nodes.map((node: Node) => serialize(node))}</div>;
};

export default RichTextBuilder;
