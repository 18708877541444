import { useLocation } from "react-router-dom"
import { RouteStateI } from "../types"

export const useTitle = () => {
  const { pathname, state } = useLocation<RouteStateI>()
  let title
  let navTitle
  const isLocation = pathname.split("/")[1]
  if (isLocation === "location") {
    title = 'Mon espace';
  }
  else {
    switch (pathname) {
      case "/moncompte":
        break
      case "/infos":
        title = 'Ajouter les informations sur votre établissement';
        navTitle = "Établissement"
        break
      case "/plan":
        title = "Séléctionnez un forfait";
        navTitle = "Forfait"
        break
      case "/sponsorisation":
        if (state?.status === "renewal") {
          title = `Renouvelement de l'offre: souhaitez vous adoptez l'option Emplacement sponsorisé ?`

        } else {
          title = "Adoptez l'option Emplacement sponsorisé"
        }
        navTitle = "Sponsorisation"
        break
      case "/validation":
        title = 'Validez votre demande';
        navTitle = "Validation"
        break

      default:
        break
    }
  }


  return { title, navTitle }
}