import zamaniLogo from "./assets/zamani-logo.png"
import mtnLogo from "./assets/mtn-web-logo.png"
import mntFavicon from "./assets/mtn-favicon.png"
import laserLogo from "./assets/laser-logo.png"

const base = {
  appName: 'Laser App',

  colors: {
    primary: '#222222', // bcp de trucs par défaut (indicateurs, menus, liens) + quelques trucs à nous (indicateur de tab)
    navBarBg: '#222222',
    navBarFont: '#999999',
    btnBg: '#337aff',
    btnFont: '#ffffff',
    tabFont: '#337aff',
    accent: '#337aff',
  },
  brand: {
    name: 'Lasercats',
    logo: laserLogo,
    favicon: laserLogo
  },
  fonts: {
    familyText: 'Avenir Light',
    familyHeading: 'Avenir Black',
  },
  sections: {
    services: 'Services',
    offers: 'Deals',
    news: 'Actu',
    directory: 'Magasins',
  },
  categories_order: ['directory', 'offers', 'services', 'stories' ],
  API: "https://selfcare-backend-base.herokuapp.com/api/v2",
  website_url: 'https://selfcare-website-base.herokuapp.com/',
  googlePlacesKey: 'AIzaSyB0QdBsX3GcGZWNUHtasud9dGux_KSVTMw',
  countryCode: 'fr',
  users: true,
  phonePrefix: "33",
  sponsorship_options: {
    2: 6,
    4: 1000
  },
  firebaseConfig: {
    apiKey: "AIzaSyC2WlPGr9cW6fiCiA_91gVDCFYWgV4Jr9U",
    authDomain: "selfcare-dev-4f0c2.firebaseapp.com",
    projectId: "selfcare-dev-4f0c2",
    storageBucket: "selfcare-dev-4f0c2.appspot.com",
    messagingSenderId: "240327210023",
    appId: "1:240327210023:web:ac432ceb7f44594adc4a02",
    measurementId: "G-FDQCVLW81F"
  }
};

const zamani = {
  appName: 'Zam Store',

  colors: {
    primary: '#337aff', // bcp de trucs par défaut (indicateurs, menus, liens) + quelques trucs à nous (indicateur de tab)
    navBarBg: '#fff517',
    navBarFont: '#De00ff',
    btnBg: '#337aff',
    btnFont: '#ffffff',
    tabFont: '#337aff',
    accent: '#337aff',
  },
  brand: {
    name: 'Zamani Telecom',
    logo: zamaniLogo,
    favicon: zamaniLogo
  },
  fonts: {
    familyText: 'Avenir Light',
    familyHeading: 'Avenir Black',
  },
  sections: {
    services: 'Services',
    offers: 'Bons Plans',
    news: 'Actualités',
    directory: 'Annuaire',
  },
  // categories_order: ['offers', 'services', 'stories', 'directory'],
  API: process.env.REACT_APP_API_URL === "base" ? "https://selfcare-backend-base.herokuapp.com/api/v2" : "https://selfcare-backend-zamani.herokuapp.com/api/v2",
  website_url: 'https://zamani-selfcare.innovacontents.com/',
  googlePlacesKey: 'AIzaSyB0QdBsX3GcGZWNUHtasud9dGux_KSVTMw',
  countryCode: 'ne',
  users: false,
  phonePrefix: "227",
  sponsorship_options: {
    30: 15000,
    90: 30000
  },
  firebaseConfig: {
    apiKey: 'AIzaSyB7_W9Bly4KN_OO03QzNCgLE8-B-VzmoE8',
    authDomain: 'selfcare-zamani.firebaseapp.com',
    projectId: 'selfcare-zamani',
    storageBucket: 'selfcare-zamani.appspot.com',
    messagingSenderId: '44642644510',
    appId: '1:44642644510:web:c48c519abe9ede38a7b40f',
    measurementId: '${config.measurementId}',
  },
};

const mtnBenin = {
  appName: 'Yello Biz',
  colors: {
    primary: '#FFCC00',
    navBarBg: '#FFCC00',
    navBarFont: '#292929',
    btnBg: '#FFCC00',
    btnFont: '#292929',
    tabFont: '#292929',
    accent: '#FFCC00',
  },
  brand: {
    name: 'MTN Bénin',
    logo: mtnLogo,
    favicon: mntFavicon
  },
  fonts: {
    familyText: 'Avenir Light',
    familyHeading: 'Avenir Black',
  },
  sections: {
    offers: 'Bons Plans',
    services: 'Services',
    news: 'Actualités',
    directory: 'Annuaire',
  },
  API: process.env.REACT_APP_API_URL === "base" ? "https://selfcare-backend-base.herokuapp.com/api/v2" : "https://mtn-selfcare-production.herokuapp.com/api/v2",
  website_url: 'https://mtn-yellobiz-selfcare.innovacontents.com',
  googlePlacesKey: 'AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk',
  countryCode: 'bj',
  users: true,
  phonePrefix: "229",
  sponsorship_options: {
    30: 25000,
    90: 50000
  },
  firebaseConfig: {
    apiKey: "AIzaSyA_g2Iw-Ix6jzwKlgu6za524GxetztmIPg",
    authDomain: "mtn-yello-business.firebaseapp.com",
    databaseURL: "https://mtn-yello-business.firebaseio.com",
    projectId: "mtn-yello-business",
    storageBucket: "mtn-yello-business.appspot.com",
    messagingSenderId: "1057593631632",
    appId: "1:1057593631632:web:f9c0dc5b43450582e40915",
    measurementId: "G-BFQ1B0M0RG"
  },
};

const configuration = (): appConfigType => {
  switch (process.env.REACT_APP_CLIENT) {
    case "zamani": return zamani
    case "mtn-benin": return mtnBenin
    case "base": return base
    default: return base
  }
}
interface appConfigType {
  [key: string]: any,
  sections: {
    [key in "offers" | "news" | "services" | "directory"]: string
  },
  sponsorship_options: {
    [key: number]: number
  }
}

export default configuration();
