import React, { memo, useState } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { CircularProgress, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { isEqual } from "lodash"
import { RegistrationI } from '../../types';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width:"50%",
      marginTop: theme.spacing(5),
     marginRight:theme.spacing(2),
    },
    textField2: {
      width:"50%",
      marginTop: theme.spacing(5),
 
    },
    form: {
      marginLeft: "auto",
      marginRight: "auto",
      // maxWidth: 600,
      display: "flex",
      // flexDirection: "column",
      // marginBottom: theme.spacing(4),
      // paddingBottom: theme.spacing(4),
    },
    mapContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      width: "100%",
      height: 520,
    },
    mapTypo: {
      marginBottom: theme.spacing(2),
    },
    category: {
      marginTop: theme.spacing(2),
    },

  })
);


const containerStyle = {
  maxWidth: "600px",
  height: '500px'
};

const cotonouPosition = {
  lat: 6.36,
  lng: 2.40
};

type Props = {
  infos: RegistrationI;
  setInfos: (value: React.SetStateAction<RegistrationI>) => any
}

const GMap = ({ infos, setInfos }: Props) => {
  const googleMapAPIKey = "AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk"
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapAPIKey // ,
  })
  const classes = useStyles();

  const handleClick = (e: any) => {
    setInfos({
      ...infos, location: {
        ...infos.location,
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng()
      }
    })
    setControlledLongitude(e.latLng.lng())
    setControlledLatitude( e.latLng.lat())
  }

  const validatePosition = (event: any) => {
    setInfos({
      ...infos, location: {
        ...infos.location,
        latitude:controlledLatitude,
        longitude: controlledLongitude,
      }
    })
  }

  // const handleLong = (event: any) => {
  //   setInfos({
  //     ...infos, location: {
  //       ...infos.location,
  //       longitude: parseFloat(event.target.value),
  //     }
  //   })
  // }

  const handleLong = (event: any) => {
    setControlledLongitude(parseFloat(event.target.value) || null )
  }

  const handleLat = (event: any) => {
    setControlledLatitude(parseFloat(event.target.value  ) || null)
  }



  const [controlledLatitude, setControlledLatitude] = useState(infos.location.latitude)
  const [controlledLongitude, setControlledLongitude] = useState(infos.location.longitude)

  useEffect(() => {
    if (infos?.location?.latitude && infos?.location?.longitude) {
      const lat = infos.location.latitude;
      const lng = infos.location.longitude;
      (async () => {
        const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk`, { method: "GET" },);
        const result = await data
          .json()
          .then((res: any) => {
            if (res?.results && res?.results[0]?.formatted_address) {
              setInfos({
                ...infos, location: {
                  ...infos.location,
                  address: res.results[0].formatted_address,
                }
              })
            }
          }
          ).catch(err => console.log(err));
      })()

    }
  }, [infos.location.longitude, setInfos])

  const [currentPosition, setCurrentPosition] = useState(cotonouPosition);

  const success = (position: any) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    setCurrentPosition(currentPosition);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, [])


  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // const onLoad = React.useCallback(
    //   function onLoad (mapInstance) {
    //     // do something with map Instance
    //   }
    // ) 
    return  <>  <GoogleMap
      mapContainerStyle={containerStyle}
      center={(isEqual(cotonouPosition, currentPosition)) ? cotonouPosition : currentPosition}
      zoom={10}
      onClick={(e) => handleClick(e)}
    >
      {infos.location?.longitude && infos.location?.latitude &&
        <Marker position={{ lat: infos.location.latitude, lng: infos.location.longitude }}/>
        
        }
    </GoogleMap>

 <div className={classes.form}>

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        defaultValue={infos.location.latitude}
        value={controlledLatitude}
        onChange={(event)=>handleLat(event)}
        onBlur={(event) => validatePosition(event)}
        label={`latitude`}
        rows={1}
        type="number"
        inputProps={{step: 0.01}}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        className={classes.textField2}
        defaultValue={infos.location.longitude}
        value={controlledLongitude}
        onBlur={(event) => validatePosition(event)}
        onChange={(event)=>handleLong(event)}
        label={`longitude`}
        rows={1}
        type="number"
        inputProps={{step: 0.01}}
      />
 </div>
</>
  }



  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <CircularProgress color="secondary" />

}

export default memo(GMap)
