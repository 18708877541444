import { useState, useContext } from "react"
import validate from "validate.js";
import RegistrationContext from "../registrationContext";
import { textConstraints, urlConstraints, emailConstraints, isEmptyConstraint } from "./constraints";


export const useValidator = () => {

  const [errorMessage, setErrorMessage] = useState<{
    title: null | string,
    headline: null | string,
    text: null | string,
    web: null | string,
    email: null | string,
    general: null | string
  }>({
    title: null,
    headline: null,
    text: null,
    web: null,
    email: null,
    general: null
  });
  const { infos, setInfos } = useContext(RegistrationContext)


  const checkField = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    let check;
    switch (field) {
      case "title":
        check = validate({ text: event.target.value }, textConstraints);
        if (check) {
          setErrorMessage({ ...errorMessage, title: check.text[0] });
          setInfos({ ...infos, errors: { ...errorMessage, title: check.text[0] } })
        }
        break;
      case "web":
        if (validate({ isEmpty: event.target.value }, isEmptyConstraint)) {
          setErrorMessage({ ...errorMessage, web: null });
          setInfos({ ...infos, errors: { ...errorMessage, web: null } })

          break;
        }
        check = validate({ web: event.target.value }, urlConstraints);
        if (check) {
          setErrorMessage({ ...errorMessage, web: check.web[0] });
          setInfos({ ...infos, errors: { ...errorMessage, title: check.web[0] } })
        }
        break;
      case "email":
        if (validate({ isEmpty: event.target.value }, isEmptyConstraint)) {
          setErrorMessage({ ...errorMessage, email: null });
          setInfos({ ...infos, errors: { ...errorMessage, email: null } })

          break;
        }
        check = validate({ mail: event.target.value }, emailConstraints);
        if (check) {
          setErrorMessage({ ...errorMessage, email: check.mail[0] });
          setInfos({ ...infos, errors: { ...errorMessage, title: check.mail[0] } })

        }
        break;
      default:
        break;
    }
  };

  return { checkField, errorMessage, setErrorMessage }
}