import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import { ProductI } from '../../types';
import { plainTextDate } from '../../utils/formatDate';
import Plan from './Plan';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
      maxWidth: 600,

    },
    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    container: {
      maxWidth: 600,
      margin: "auto"
    },
    spaceY2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

    }

  })
);

const ProductInfos = ({ product, noCard }: { product: ProductI, noCard?: boolean }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography color="textSecondary" className={classes.spaceY2} variant="h6">
        {product.relation_type === "add_sponso" ? "Sponsorisation" : "Plan"}
      </Typography>
      {(product.relation_type !== "add_sponso") && <Typography>Plan {product.relation_value}</Typography>}
      {product.duration_in_months && <Typography>Durée {product.duration_in_months} mois</Typography>}
      {/* {product.date_start && <Typography>Début: {format(Date.parse(product.date_start), 'dd MMM yyyy', { locale: frenchLocale })}</Typography>} */}
      {product.date_end && <Typography>Fin: {plainTextDate(product.date_end)}</Typography>}
      {(product.relation_value === "Bronze" || product.relation_value === "Silver" || product.relation_value === "Gold") && !noCard && <Plan
        title={product.relation_value}
      />}
    </div>
  )
}

export default ProductInfos
