import React from 'react'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Paper, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import clsx from "clsx"
import { DynamicPlanI, RouteStateI } from '../../types';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { fetchOne } from '../../API';
import { useUserContext } from '../../authentication';
import { usePlan } from '../../utils/usePlan';
import RichTextBuilder from './RichTextBuilder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)

    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      maxWidth: 600,
      marginRight: "auto",
      marginLeft: "auto"
    },
    goldBg: {
      backgroundColor: "#D4AF37"
    },
    silverBg: {
      backgroundColor: "#8D8787"
    },
    bronzeBg: {
      backgroundColor: "#614E1A"
    },
    white: {
      color: "white"
    },
    btn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: "white",
      color: "black",
      width: "100%",
      "&:hover": {
        backgroundColor: 'white'
      },
      "&:focus": {
        backgroundColor: 'white'
      }
    },
    selectedBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "100%"
    },
    price: {
      fontWeight: theme.typography.fontWeightBold
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold
    },
  })
);

type optionsProps = {
  options: string[]
}

const OptionsPlan = ({ options }: optionsProps) => {
  const classes = useStyles()

  return (
    <>
      {options.map(option =>
        <div key={option} className={classes.optionContainer}>
          <CheckIcon classes={{ root: classes.white }} />
          <Typography classes={{ root: classes.white }}>{option}
          </Typography>
        </div>
      )}
    </>
  )

}

type Props = {
  title: DynamicPlanI["name"];
  choosePlan?: (plan: DynamicPlanI["name"], priceCents: DynamicPlanI["price_cents"]) => void;
  selectedPlan?: string;
  deletePlan?: () => void
}

const Plan = ({ deletePlan, selectedPlan, title, choosePlan }: Props) => {
  const classes = useStyles()
  const { accessToken } = useUserContext()
  const { state } = useLocation<RouteStateI>()

  const plan = usePlan(title)
  const { data } = useQuery(
    ["locations", state?.locationId, `upgrade_plan?type=${title}`],
    () => {
      if (state?.locationId) {
        return fetchOne("locations", { id: state?.locationId }, accessToken, `upgrade_plan?type=${title}`)
      }
    },
    { enabled: Boolean(state?.status === "upgrade" && state?.locationId) }
  )
  const upgradePrice = data?.price

  return (
    <div>

      {plan && <Paper className={clsx({
        [classes.white]: true,
        [classes.container]: true,
        [classes.goldBg]: title === "Gold",
        [classes.silverBg]: title === "Silver",
        [classes.bronzeBg]: title === "Bronze"
      })}>
        <Typography classes={{ root: classes.white }} variant="h6">{plan.printed_name}</Typography>
        {plan.sub_title && !upgradePrice && <RichTextBuilder nodes={plan.sub_title} />}
        {upgradePrice && <Typography className={classes.bold} >reste à payer {upgradePrice.cents} FCFA jusqu'à la fin de votre abonnement</Typography>}
        {plan.description && <RichTextBuilder nodes={plan.description} />}

        {/* {getOptions()} */}
        <div>
          {selectedPlan === title ?
            deletePlan && <Button
              className={classes.selectedBtn}
              variant="contained"
              color="secondary"
              onClick={() => deletePlan()}
            // classes={{ root: classes.btn }
            >
              Ce plan est selectionné
            </Button>
            :
            choosePlan &&
            <Button
              onClick={() => choosePlan(title, plan.price_cents)}
              classes={{ root: classes.btn }}>
              Choisir ce plan
            </Button>}

        </div>

      </Paper>}
    </div>
  )
}

export default Plan
