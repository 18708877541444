import { Deserializer } from "jsonapi-serializer";
import { ActionI, DynamicPlanI } from "./types";
import appConfig from "./appConfig";

/////////////
// API V2 //
///////////

const API = appConfig.API;

type modelType = "app_configurations" |"articles" | "locations" | "users" | "tokens" | "categories" | "payments" | "products" | "salespeople" | "plans";

type optionsType = {
  id?: string;
  include?: string[];
  fields?: {
    [model: string]: string[];
  };
  page?: { size: number; number: number };
  sort?: string;

  deserializerOptions?: {
    [key: string]: {
      valueForRelationship: (relationShip: object) => object;
    };
  };
};

const postDeserialize = (res: any, model: modelType) => {
  if (model === "articles") {
    if (res.actions) {
      res.actions = JSON.parse(res.actions);
    }
    if (res.rich_text) {
      res.rich_text = JSON.parse(res.rich_text);
    }
  }
  if (model === "plans") {

    const formattedPlan = (plan: DynamicPlanI) => {
      if (plan.sub_title) {
 
        plan.sub_title = JSON.parse(plan.sub_title);
      }
      if (plan.description) {
        plan.description = JSON.parse(plan.description);
      }
    }


    if (res.length) {
      res.forEach((el: DynamicPlanI) => {
        formattedPlan(el)
      });
    } else {
      res = formattedPlan(res)
    }

  }
  return res;
};

const fetchFn = async (
  model: modelType,
  {
    id,
    include: rawInclude,
    fields: rawFields,
    page: rawPage,
    sort: rawSort,
  }: optionsType,
  accessToken?: string | null,
  action?: string
) => {
  const fields = rawFields
    ? Object.entries(rawFields)
      .map(([key, val]) => `&fields[${key}]=${val.join(",")}`)
      .join("&")
    : "";
  const include = rawInclude ? `&include=${rawInclude.join(",")}` : "";
  const page = `${rawPage
    ? `${rawPage.size ? `&page[size]=${rawPage.size}` : ""}${rawPage.number !== undefined && rawPage.number !== null
      ? `&page[number]=${rawPage.number}`
      : ""
    }`
    : ""
    }`;

  const sort = rawSort ? `&sort=${rawSort}` : "";

  let url = `${API}/${model}${id ? `/${id}` : ""
    }?${include}${fields}${page}${sort}`;
  if (action && id) {
    url = `${API}/${model}/${id}/${action}`
  }
  const headers = {
    ...(accessToken && { Authorization: accessToken }),
    'Content-Type': 'application/vnd.api+json',
  };

  const response = await fetch(url, { headers });
  // To be handled by react-query !
  // To be handled by react-query !
  if (!response.ok) {
    const text = await response.text();
    throw {status: response.status, statusMessage: response.statusText, text }
  }
  return response.json();
};

export const createOne = async (
  model: modelType,
  {
    attributes,
    relationships,
  }: any,
  accessToken?: string | null,
  action?: "claim" | "submit" | "send_codes",
  id?: string
) => {
  let url = `${API}/${model}${action === "send_codes" && id ? `/${id}` : ""}/${action ? `${action}` : ""}${action === "claim" && id ? `?location_source_id=${id}` : ""}`

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      ...(accessToken && { Authorization: accessToken }),
    },
    body: JSON.stringify({ data: { attributes, relationships, type: model } }),
  });

  if (!response.ok) {
    const text = await response.text();
    throw {status: response.status, statusMessage: response.statusText, text }
  }

  return response
};

export const fetchOne = async (
  model: modelType,
  {
    id,
    include,
    fields,
    deserializerOptions = {},
  }: optionsType & { id: string },
  accessToken?: string | null,
  action?: string
) => {

  const data = await fetchFn(model, { include, fields, id }, accessToken, action);

  if (data?.data?.attributes) {
    const deserializedData = await new Deserializer({
      keyForAttribute: "snake_case",
      ...deserializerOptions,
    }).deserialize(data, (err, res) => postDeserialize(res, model));
  
    return {
      result: deserializedData as any,
      // pageCount: data.meta.page_count,
      // total: data.meta.record_count,
    };
  } else { 
   return data }

};

export const fetchList = async (
  model: modelType,
  { include, fields, deserializerOptions = {}, page, sort }: optionsType,
  accessToken?: string | null
) => {
  const data = await fetchFn(
    model,
    { include, fields, page, sort },
    accessToken
  );
  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
    ...deserializerOptions,
  }).deserialize(data, (err, res) => postDeserialize(res, model));
  return {
    results: (deserializedData as unknown) as any[],
    pageCount: data.meta?.page_count || null,
    total: data.meta?.record_count || null,
  };
};

// Update

export const updateFn = async (
  model: modelType,
  { attributes, relationships, id }: any,
  accessToken?: string | null
) => {
  const response = await fetch(`${API}/${model}/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      ...(accessToken && { Authorization: accessToken }),
    },
    body: JSON.stringify({
      data: { id, attributes, relationships, type: model },
    }),
  });
  if (!response.ok) throw new Error(response.statusText);
  return await response.json();
};

export const updateOne = async (
  model: modelType,
  { id, attributes, relationships }: any,
  accessToken?: string | null
) => {
  const data = await updateFn(
    model,
    { attributes, relationships, id },
    accessToken
  );
  const deserializedData = await new Deserializer({
    keyForAttribute: "snake_case",
  }).deserialize(data, (err, res) => postDeserialize(res, model));
  return { result: deserializedData };
};






//////////// NOT IN JSON API //////////////


type actionateI = {
  action?: ActionI,
  type?: DynamicPlanI["name"] | string,
  id?: string
  accessToken?: string
  email?: string
  model: modelType
  salespersonId?:any
  method?: 'PATCH' | 'POST'
}


export const actionate = async ({ action, type, id, accessToken, email, model, salespersonId, method }: actionateI) => {
  const config = {
    method: method? method : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken ? accessToken : ""
    },
  };
  // const response = await fetch(`${API}/${model}${id ? `/${id}` : ""}/${action}?${type?`type=${type}` : ""}${email ? `email=${email}` : ""} `, config)
const response = await fetch(`${API}/${model}${id ? `/${id}` : ""}/${action}?${type?`type=${type}` : ""}${email ? `email=${email}` : ""}${salespersonId ? `salesperson_id=${salespersonId}` : ""} `, config)
  if (!response.ok) {
    const text = await response.text();
    throw {status: response.status, statusMessage: response.statusText, text }
  }
    return response;
}


