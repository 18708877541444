import React from "react";
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Link,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useUserContext } from "../authentication";
import { AccountCircle } from "@material-ui/icons";
import appConfig from "../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flexGrow: 1,
      cursor: "pointer",
    },
    title: {
      marginLeft: theme.spacing(2),
      fontSize: 20,
      color: theme.palette.navBar.contrastText
    },
    appBar: {
      backgroundColor: theme.palette.navBar.main,
      zIndex: theme.zIndex.drawer + 1,
    },
    bg: {
      color: theme.palette.navBar.contrastText,
    },
    logo: {
      width: 80,
    },
    headerRight: {
      display: "flex",
      flexDirection: "row",
    },
    circle: {
      color: theme.palette.navBar.contrastText,
    },
    login: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: theme.spacing(2),
    },
  })
);

const AppBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const authentication = useUserContext();

  // Right menu logic
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MaterialAppBar
        classes={{ colorPrimary: classes.bg }}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <Link
            underline="none"
            color="textPrimary"
            variant="h6"
            onClick={() => history.push("/")}
            className={classes.left}
          >
            <img className={classes.logo} src={appConfig.brand.logo} alt="logo" />
            <h1 className={classes.title}>{appConfig.appName}</h1>
          </Link>

          {authentication?.user && (
            <div className={classes.headerRight}>
              {authentication && authentication.user && (
                <Typography className={classes.login} variant="body1">
                  {authentication.user.name}
                </Typography>
              )}
              <IconButton
                color="primary"
                aria-label="user"
                onClick={handleClick}
              >
                <AccountCircle className={classes.circle} />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </MaterialAppBar>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          // disabled={index === 0}
          onClick={() => {
            handleClose();
            history.push("/mesinfos");
          }}
        >
          Mon compte
        </MenuItem>
        <MenuItem
          // disabled={index === 0}
          onClick={() => {
            handleClose();
            authentication && authentication.logOut();
            history.push("/");
          }}
        >
          Se déconnecter
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppBar;
