import { differenceInDays } from "date-fns"
import { useState, useEffect } from "react"


export const useSponso = (sponsored_end_at: any, sponsored_start_at: any) => {
  const [sponsoType, setSponsoType] = useState<number>()

  useEffect(() => {
    if (sponsored_end_at && sponsored_start_at) {
      setSponsoType(differenceInDays(sponsored_end_at, sponsored_start_at))

    }

  }, [])

  return sponsoType
}